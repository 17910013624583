<template>
  <div
    v-if="loadedPage"
    class="admin-content--container">
    <div class="admin-search-container">
      <InputField
        v-model="query"
        placeholder="Find activation..."
        trailingIcon="magnifyingGlass"
        clickableTrailingIcon
        @trailingIconClick="findAdminActivations()"
        @enterKey="findAdminActivations()"/>

      <div class="add-entity-button" @click="showActivationModal = true">
        <iconComponent symbol="circledPlus" />
      </div>

      <div
        class="column-picker"
        @focus="filterOptions = true"
        @blur="filterOptions = false">
        <button
          class="btn btn-activate"
          @click="filterOptions = !filterOptions">
          Filters
        </button>
        <div
          v-show="filterOptions"
          class="column-options">
          <div
            class="column-option"
            v-for="filter in filters"
            :class="{ 'active-column-option': filter.active }"
            :key="`column-option-${filter.id}`">
            <div @click="filter.func ? filter.func() : filter.active = !filter.active">
              {{ filter.name }}
            </div>
          </div>
        </div>
      </div>
    </div>

    <aList
      entityType="PV Activations"
      columnsWidth="400"
      :entities="activations"
      :keys="activationKeys"
      routerName="AdminPVActivation"
      routerId="activationId"/>

    <modal v-if="showActivationModal"
      :header="{text: 'Add pv activation', closeButton: true, textPosition: 'center', bgColor: '#F2F2F2'}"
      @close="showActivationModal = false"
      class="modal-view"
      modalType="fullscreen">
      <slot>
        <EntityEditor
          title="Create activation"
          :admin="true"
          entity-type="PVActivation"/>
      </slot>
    </modal>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import moment from 'moment';
import BeforeEnterMixin from '../mixins/BeforeEnterMixin.vue';
import EventBus from "../resources/eventBus";
import aList from "../components/AList";
import InputField from '../stories/components/InputField/InputField.vue';
import EntityEditor from '../stories/components/EntityEditor/EntityEditor.vue';

export default {
  name: "AdminPVActivations",
  components: {
    aList,
    EntityEditor,
    InputField,
  },
  mixins: [BeforeEnterMixin],
  mounted() {
    this.findAdminActivations();
    this.setPageTitle('Admin', 'PV Activations');
  },
  destroyed() {
    EventBus.$emit('selected', '');
  },
  computed: {
    ...mapGetters('pvActivations', ["activations", "activationsStatus"])
  },
  watch: {
    filters: {
      handler() {
        let query = JSON.parse(JSON.stringify(this.$route.query));
        query.filt = [];
        for (let i = 0; i < this.filters.length; i++) {
          const filter = this.filters[i];
          if (filter.active && !filter.func) {
            query.filt.push(filter.id);
          }
        }
        this.$router.replace({ query });
        // this.resetPagination();
        this.findAdminActivations();
      },
      deep: true
    },
  },
  data() {
    return {
      showActivationModal: false,
      loadedPage: false,
      query: '',
      activationKeys: [
        {
          title: 'Id',
          key: '_id'
        },
        {
          title: 'Name',
          key: 'name'
        },
        {
          title: 'Public',
          key: 'public',
          style: [{ color: 'red', value: false }, { color: 'green', value: true }],
        },
        {
          title: 'Market',
          key: 'market'
        },
        {
          title: 'Info',
          key: 'info'
        },
        {
          title: 'Company',
          key: 'company.name'
        },
        {
          title: 'Created At',
          key: 'created_at',
          unix: 'YYYY-MM-DD HH:mm:ss',
        },
      ],
      filterOptions: false,
      filters: [
        { name: 'Public', active: false, filter: { public: true } },
        { name: 'Hidden', active: false, filter: { public: false } },
        { name: 'Has cta actions', active: false, filter: { cta_actions: { $exists: true, $ne: [] } } },
        { name: 'Has no cta actions', active: false, filter: { cta_actions: { $exists: true, $eq: [] } } },
      ],
    };
  },
  methods: {
    findAdminActivations() {
      this.$store.dispatch('pvActivations/findAdminPVActivation', {
        query: this.query,
        filters: this.getActiveFilters(),
      }).then(() => {
        this.loadedPage = true;
      });
    },
    previewMedia(activation) {
      this.activationPreview = activation;
    },
    openOfferDialog(data) {
      this.activationId = data.activationId;
      this.showOfferDialog = true;
    },
    setActiveFilters() {
      const { filt } = this.$route.query;
      const { created_at_start, created_at_end } = this.$route.query;
      this.createdStart = created_at_start ? Number(created_at_start) : 1672578061000;
      this.createdEnd = created_at_end ? Number(created_at_end) : (new moment()).valueOf();
      if (filt && filt.length) {
        for (let i = 0; i < this.filters.length; i++) {
          const filter = this.filters[i];
          if (filt.includes(filter.id)) {
            filter.active = true;
          } else if (filter.id == 'datemodal') {
            filter.active = true;
          } else {
            filter.active = false;
          }
        }
      }
    },
    getActiveFilters() {
      let realFilters = {};
      for (let i = 0; i < this.filters.length; i++) {
        const filter = this.filters[i];
        if (filter.active) {
          Object.assign(realFilters, filter.filter);
        }
      }
      return realFilters;
    },
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
.spinner-overlay {
  position: absolute;
  top: 25%;
  width: 97%;
  margin: auto;
  z-index: 1000;
}
.adver-card {
  border: 1px solid grey;
}
.activation-list {
}
.single-list{
}
.the-list {
  display: grid;
  justify-content: center;
  grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
  grid-gap: 1px;
  .a-list-item {
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    border: 1px solid grey;
    height: 100px;
    .activation-item-image{
      width: fit-content;
      height: fit-content;
    }
    .activation-item-title{
      color: white;
      text-align: center;
      position: absolute;
      top: 0;
    }
  }
}
.activation-preview{
  display: grid;
  // box-shadow: -1px 0px 2px grey;
  min-height: calc(100vh - 48px);
  .close-preview{
    cursor: pointer;
    width: fit-content;
    position: absolute;
    top: 2px;
    right: 5px;
  }
}
.add-activation-button{
  z-index: 100;
  position: absolute;
  bottom: 5rem;
  right: 5rem;
}
.close-preview-button{
  position: absolute;
  top: 5rem;
  right: 1rem;
}
.column-picker{
  margin-left: 10px;
}
</style>
